import React, { useState } from "react";
import classes from "./Staking.module.css";
import stakeIcon from "../assets/stake.png";
import unstakeIcon from "../assets/unstake.png";
import withdrawIcon from "../assets/withdraw.png";
import reinvestIcon from "../assets/reinvest.png";
import icon from "../assets/icon.png";

const Staking = (props) => {
  const [inputValue, setInputValue] = useState("");
  const [isMax, setIsMax] = useState(false);
  const [action, setAction] = useState("stake"); // default action

  const inputChangeHandler = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setInputValue(value);
    setIsMax(false);
    props.inputHandler(value);
  };

  const maxChangeHandler = (event) => {
    const checked = event.target.checked;
    setIsMax(checked);
    if (checked) {
      let maxValue = 0;
      if (action === "stake") {
        maxValue = props.userBalance;
      } else if (action === "unstake") {
        maxValue = props.myStake;
      } else if (action === "withdrawRewards" || action === "reinvestRewards") {
        maxValue = props.userRewards;
      }
      setInputValue(maxValue);
      props.inputHandler(maxValue);
    } else {
      setInputValue("");
      props.inputHandler("");
    }
  };

  return (
    <div className={classes.Staking}>
      <img src={icon} alt="logo" className={classes.icon} />
      <h1>MagiVerse Staking dApp</h1>
      <p>{props.account}</p>
      <div className={classes.inputDiv}>
      <span></span>
        <input
          className={classes.input}
          type="number"
          min="0"
          step="1"
          onChange={inputChangeHandler}
          value={inputValue}
          disabled={isMax}
        />
        <label className={classes.maxLabel}>
          <input
            type="checkbox"
            className={classes.maxCheckbox}
            onChange={maxChangeHandler}
            checked={isMax}
          />
          Max
        </label>
      </div>
      <div className={classes.buttonGroup}>
        <button
          className={classes.stakeButton}
          onClick={() => {
            setAction("stake");
            props.stakeHandler();
            setInputValue("");
            setIsMax(false);
          }}
          disabled={!props.isNetworkValid}
        >
          <img src={stakeIcon} alt="stake icon" className={classes.stakeIcon} />
          <p>Stake</p>
        </button>
        <button
          className={classes.unstakeButton}
          onClick={() => {
            setAction("unstake");
            props.unStakeHandler();
            setInputValue("");
            setIsMax(false);
          }}
          disabled={!props.isNetworkValid}
        >
          <img
            src={unstakeIcon}
            alt="unstake icon"
            className={classes.stakeIcon}
          />
          <p>Unstake</p>
        </button>
      </div>
      <div className={classes.buttonGroup}>
        <button
          className={classes.withdrawRewardsButton}
          onClick={() => {
            setAction("withdrawRewards");
            props.withdrawRewardsHandler();
            setInputValue("");
            setIsMax(false);
          }}
          disabled={!props.isNetworkValid}
        >
          <img
            src={withdrawIcon}
            alt="withdraw icon"
            className={classes.stakeIcon}
          />
          <p>Withdraw Rewards</p>
        </button>
        <button
          className={classes.reinvestRewardsButton}
          onClick={() => {
            setAction("reinvestRewards");
            props.reinvestRewardsHandler();
            setInputValue("");
            setIsMax(false);
          }}
          disabled={!props.isNetworkValid}
        >
          <img
            src={reinvestIcon}
            alt="reinvest icon"
            className={classes.stakeIcon}
          />
          <p>Reinvest Rewards</p>
        </button>
      </div>
      <div className={classes.totals}>
        <h4>My Current Rewards: {props.userRewards} MAGI</h4>
      </div>

      <div className={classes.bottomBar}>
        <div className={classes.bottomBarContent}>
          <span>My Stake: {props.myStake} MAGI</span>
          <span onClick={maxChangeHandler}>
            My balance: {props.userBalance} MAGI
          </span>
          <span>Total Shares: {props.totalShares}</span>
          <span>My Shares: {props.myShares}</span>
          <span>Total Staked (by all users): {props.totalStaked} MAGI</span>
          <span>Total Rewards: {props.currentRewards} MAGI</span>
        </div>
      </div>
    </div>
  );
};

export default Staking;
