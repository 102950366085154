import React, { useState, useEffect, useCallback } from "react";
import Web3 from "web3";
import Staking from "./components/Staking";
import ERC20ABI from "./abi/ERC20.json";
import TokenStakingABI from "./abi/TokenStaking.json";
import "./App.css";

const TOKEN_ADDRESS = "0xba5ed69ad282ec55a2f50bdeed948792dc43d31f";
const TOKEN_STAKING_ADDRESS = "0xc882266d9502a90cEE107aC87d40d9Cb3C77641F";

function App() {
  const [account, setAccount] = useState("Connecting to Metamask..");
  const [erc20Contract, setErc20Contract] = useState(null);
  const [decimals, setDecimals] = useState(null);
  const [tokenStakingContract, setTokenStakingContract] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [totalStaked, setTotalStaked] = useState(0);
  const [myStake, setMyStake] = useState(0);
  const [totalShares, setTotalShares] = useState(0);
  const [myShares, setMyShares] = useState(0);
  const [userRewards, setUserRewards] = useState(0);
  const [currentRewards, setCurrentRewards] = useState(0);
  const [loader, setLoader] = useState(true);
  const [userBalance, setUserBalance] = useState("0");
  const [isNetworkValid, setIsNetworkValid] = useState(false);

  const fetchDataFromBlockchain = useCallback(async () => {
    if (window.ethereum) {
      await window.ethereum.request({ method: "eth_requestAccounts" });
      const web3 = new Web3(window.ethereum);

      const accounts = await web3.eth.getAccounts();
      setAccount(accounts[0]);

      const networkId = await web3.eth.net.getId();
      const networkIdNum = Number(networkId);

      if (networkIdNum !== 1) {
        setAccount("Please connect to the Ethereum mainnet");
        setIsNetworkValid(false);
        setLoader(false);
        return;
      }
      setIsNetworkValid(true);

      const erc20 = new web3.eth.Contract(ERC20ABI, TOKEN_ADDRESS);
      setErc20Contract(erc20);

      const tokenDecimals = await erc20.methods.decimals().call();
      const tokenDecimalsNum = Number(tokenDecimals);
      setDecimals(tokenDecimalsNum);

      if (TOKEN_STAKING_ADDRESS) {
		
        const stakingContract = new web3.eth.Contract(
          TokenStakingABI,
          TOKEN_STAKING_ADDRESS
        );
        setTokenStakingContract(stakingContract);

        const totalStakedTokens = await stakingContract.methods
          .getTotalStakedTokens()
          .call();
        setTotalStaked(
          Number(totalStakedTokens) / Math.pow(10, tokenDecimalsNum)
        );

        const userStake = await stakingContract.methods
          .getUserStake(accounts[0])
          .call();
        setMyStake(Number(userStake) / Math.pow(10, tokenDecimalsNum));

		const totalShares = await stakingContract.methods
          .getTotalShares()
          .call();
        setTotalShares(Number(totalShares) / Math.pow(10, tokenDecimalsNum));

        const myShares = await stakingContract.methods
          .getUserShares(accounts[0])
          .call();
        setMyShares(Number(myShares) / Math.pow(10, tokenDecimalsNum));

        const currentRewards = await stakingContract.methods
          .getCurrentRewards()
          .call();
        setCurrentRewards(
          Number(currentRewards) / Math.pow(10, tokenDecimalsNum)
        );

        const userRewards = await stakingContract.methods
          .getUserReward(accounts[0])
          .call();
        setUserRewards(Number(userRewards) / Math.pow(10, tokenDecimalsNum));
      }
      const userBalance = await erc20.methods.balanceOf(accounts[0]).call();
      setUserBalance(Number(userBalance) / Math.pow(10, tokenDecimalsNum));

      setLoader(false);
    } else {
      setAccount("Metamask is not detected");
      setLoader(false);
    }
  }, []);

  useEffect(() => {
    fetchDataFromBlockchain();

    if (window.ethereum) {
      window.ethereum.on("chainChanged", () => {
        window.location.reload();
      });

      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setAccount(accounts[0]);
          fetchDataFromBlockchain();
        } else {
          setAccount("Please connect to MetaMask");
        }
      });
    }
  }, [fetchDataFromBlockchain]);

  const inputHandler = (received) => {
    setInputValue(received);
  };

  const approveTokens = async (amount) => {
    if (erc20Contract && account) {
      try {
        await erc20Contract.methods
          .approve(TOKEN_STAKING_ADDRESS, amount)
          .send({ from: account });
        setLoader(true);
      } catch (error) {
        if (error.code === 4001) {
          // User denied transaction
          alert("Transaction denied by user");
        } else {
          console.error("Error approving tokens:", error);
        }
        setLoader(false);
      }
    }
  };

  const stakeHandler = async () => {
    if (tokenStakingContract && account && isNetworkValid) {
      try {
        const amount = inputValue * Math.pow(10, Number(decimals));

        if (amount > userBalance * Math.pow(10, Number(decimals))) {
          alert("Insufficient token balance");
          return;
        }

        await approveTokens(amount);
        await tokenStakingContract.methods
          .stake(amount)
          .send({ from: account })
          .on("transactionHash", (hash) => {
            setLoader(true);
          })
          .on("receipt", (receipt) => {
            fetchDataFromBlockchain();
          })
          .on("error", (error, receipt) => {
            console.error("Error staking tokens:", error);
            setLoader(false);
          });
      } catch (error) {
        if (error.code === 4001) {
          // User denied transaction
          alert("Transaction denied by user");
        } else {
          console.error("Error staking tokens:", error);
        }
        setLoader(false);
      }
    } else {
      alert("Please connect to the Ethereum mainnet");
    }
  };

  const unStakeHandler = async () => {
    if (tokenStakingContract && account && isNetworkValid) {
      try {
        const amount = inputValue * Math.pow(10, Number(decimals));

        if (amount > myStake * Math.pow(10, Number(decimals))) {
          alert("Insufficient staked amount");
          return;
        }
        await tokenStakingContract.methods
          .withdrawStake(amount)
          .send({ from: account })
          .on("transactionHash", (hash) => {
            setLoader(true);
          })
          .on("receipt", (receipt) => {
            fetchDataFromBlockchain();
          })
          .on("error", (error, receipt) => {
            console.error("Error unstaking tokens:", error);
            setLoader(false);
          });
      } catch (error) {
        if (error.code === 4001) {
          // User denied transaction
          alert("Transaction denied by user");
        } else {
          console.error("Error unstaking tokens:", error);
        }
        setLoader(false);
      }
    } else {
      alert("Please connect to the Ethereum mainnet");
    }
  };

  const withdrawRewardsHandler = async () => {
    if (tokenStakingContract && account && isNetworkValid) {
      try {
        const amount = inputValue * Math.pow(10, Number(decimals));

        if (amount > userRewards * Math.pow(10, Number(decimals))) {
          alert("Insufficient rewards balance");
          return;
        }

        await tokenStakingContract.methods
          .withdrawRewards(amount)
          .send({ from: account })
          .on("transactionHash", (hash) => {
            setLoader(true);
          })
          .on("receipt", (receipt) => {
            fetchDataFromBlockchain();
          })
          .on("error", (error, receipt) => {
            console.error("Error withdrawing rewards:", error);
            setLoader(false);
          });
      } catch (error) {
        if (error.code === 4001) {
          // User denied transaction
          alert("Transaction denied by user");
        } else {
          console.error("Error withdrawing rewards:", error);
        }
        setLoader(false);
      }
    } else {
      alert("Please connect to the Ethereum mainnet");
    }
  };

  const reinvestRewardsHandler = async () => {
    if (tokenStakingContract && account && isNetworkValid) {
      try {
        const amount = inputValue * Math.pow(10, Number(decimals));

        if (amount > userRewards * Math.pow(10, Number(decimals))) {
          alert("Insufficient rewards balance");
          return;
        }

        await tokenStakingContract.methods
          .reinvestRewards(amount)
          .send({ from: account })
          .on("transactionHash", (hash) => {
            setLoader(true);
          })
          .on("receipt", (receipt) => {
            fetchDataFromBlockchain();
          })
          .on("error", (error, receipt) => {
            console.error("Error reinvesting rewards:", error);
            setLoader(false);
          });
      } catch (error) {
        if (error.code === 4001) {
          // User denied transaction
          alert("Transaction denied by user");
        } else {
          console.error("Error reinvesting rewards:", error);
        }
        setLoader(false);
      }
    } else {
      alert("Please connect to the Ethereum mainnet");
    }
  };

  return (
    <div className="Grid">
      {loader ? <div className="curtain"></div> : null}
      <div className="Child">
        <div>
          <Staking
            account={account}
            totalStaked={totalStaked}
            myStake={myStake}
            userBalance={userBalance}
            totalShares={totalShares}
            myShares={myShares}
            currentRewards={currentRewards}
            userRewards={userRewards}
            stakeHandler={stakeHandler}
            unStakeHandler={unStakeHandler}
            withdrawRewardsHandler={withdrawRewardsHandler}
            reinvestRewardsHandler={reinvestRewardsHandler}
            inputHandler={inputHandler}
            isNetworkValid={isNetworkValid}
          />
        </div>
      </div>
    </div>
  );
}

export default App;
